import React, {Suspense, useState, useEffect, useRef} from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { RageTree } from '../../assets/RageTree/RageTree_Web';

import './Scroll3dContainer.css';

const RotatingModel = ({ rotationSpeed }) => {

    // transform state
    const [rotY, setRotY] = useState(0);
    
    // update transform
    const modelRef = useRef();
    useFrame(() => {
        modelRef.current.rotation.y = rotY;
    })

    // scroll listener
    useEffect(() => {
        const onScroll = () => {
            //update rotation
            const newRotation = window.scrollY * rotationSpeed;
            setRotY(newRotation);
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <group ref={modelRef}>
            <RageTree position={[0, -.2, 0]}/> 
        </group>
    );
}

const Scroll3dContainer = ({ rotationSpeed, heightPercent=100 }) => {
    return (
        <div 
            className='scroll3dContainer'
            style={{
                height: `${heightPercent}vh`,
            }}
        >
            <Canvas
                camera={{ position: [8.4, 0, 0], fov: 15 }}
                style={{
                    backgroundColor: '#000000',
                    width: '100vw',
                    height: `${heightPercent}vh`,
                }}
            >
                <ambientLight intensity={0.5} />
                <pointLight intensity={1} position={[10, 10, 10]} />
                
                <Suspense fallback={null}>
                    <RotatingModel rotationSpeed={rotationSpeed} />
                </Suspense>
            </Canvas>
        </div>
    );
};

export default Scroll3dContainer;