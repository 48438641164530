/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.2 RageTree_Web.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function RageTree(props) {
  const { nodes, materials } = useGLTF('/media/models/RageTree_Web.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.textured_1.geometry} material={materials.textured_0} />
      <mesh geometry={nodes.textured_2.geometry} material={materials.textured_1} />
      <mesh geometry={nodes.textured_3.geometry} material={materials.textured_2} />
      <mesh geometry={nodes.textured_4.geometry} material={materials.textured_3} />
      <mesh geometry={nodes.textured_5.geometry} material={materials.textured_4} />
      <mesh geometry={nodes.textured_6.geometry} material={materials.textured_5} />
      <mesh geometry={nodes.textured_7.geometry} material={materials.textured_6} />
      <mesh geometry={nodes.textured_8.geometry} material={materials.textured_7} />
    </group>
  )
}

useGLTF.preload('/media/models/RageTree_Web.glb')
