import React from 'react';
import { useState, useEffect } from 'react';

import './GlitchImage.css'

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

const GlitchImage = ({ 
    imagePaths,
    altText = '', 
    sizePercentage = .42, 
    sizePercentagePortrait = .84, 
    glitchIntensity = .69 
}) => {

    // window size
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    // window size tracking
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //image scale
    const updateImageScale = () => {
        const windowIsPortrait = windowDimensions.height > windowDimensions.width;
        return windowIsPortrait ? sizePercentagePortrait : sizePercentage;
    }
    const [imageScale, setImageScale] = useState(updateImageScale());

    // update image scale
    useEffect(() => {
        setImageScale(updateImageScale());
    }, [windowDimensions]);

    // glitch image offset
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    // glitch on scroll
    useEffect(() => {
        const onScroll = () => {
            setOffsetX((2*Math.random() - 1) * glitchIntensity);
            setOffsetY((2*Math.random() - 1) * glitchIntensity);
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div className='glitchImageParent'>
            <picture 
                className='glitchImage' 
            >
                <source srcSet={imagePaths.webp} type='image/webp' />
                <source srcSet={imagePaths.png} type='image/png' />
                <img 
                    src={imagePaths.webp} 
                    alt={altText}
                    style={{
                        left: `${50 + 100*offsetX}%`,
                        top: `${50 + 100*offsetY}%`,
                        width: `${imageScale * windowDimensions.width}px`
                    }}
                />
            </picture>
        </div>
    );
};

export default GlitchImage;