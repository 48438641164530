import React, {useState, useEffect} from 'react';
import ScrollStretchSvg from '../ScrollStretchSvg/ScrollStretchSvg';
import TextBox from '../TextBox/TextBox';
import EmptyBlock from '../EmptyBlock/EmptyBlock';
import Scroll3dContainer from '../Scroll3dContainer/Scroll3dContainer';
import GlitchImage from '../GlitchImage/GlitchImage';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
      width,
      height
  };
}

const testProjectData = {
  title: 'the ocean is a blue/black/grey/brown/black ocean',
  medium: 'Interactive VR sound installation',
  year: 2022,
  caption: "'The ocean is a blue/black/grey/brown/black ocean' is based on poems created by artificial intelligence that carry a sense of melancholy, dystopia, and distress, touching on topics such as the destruction of nature, unfulfilled love, dreams, and depression. They are somewhat clear in their expression of emotion, and seem almost human-made but remain vague and ungraspable. The poems are interrupted by interludes that are an interplay between spherical, distorted synthesizers and short snippets of the poems that are rearranged in an abstract, rhythmic way, emphasizing the tension between structure and ambiguity in the texts. In total, the composition lasts about 15 minutes and is played on loop.",
  exhibitions: [
    {
      name: 'Parallel Vienna',
      date: 'September 2022',
      show: true,
    },
    {
      name: 'Digital Workflow / Angewandte Open House',
      date: 'October 2022',
      show: true,
    }
  ],
  links: [
    {
      url: 'https://www.instagram.com/p/CkqIUFks8vp/?utm_source=ig_web_button_share_sheet', 
      text: 'DK Open House on Instagram', 
      show: true
    }
  ],
  style: {
      color: '#ffffff',
      highlightColor: '#00ff33',
  },
};

const DemoPage = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  // resize handler
  useEffect(() => {
    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (<>
    <Scroll3dContainer heightPercent={80} rotationSpeed={.042} />
    {/* <EmptyBlock /> */}
    <ScrollStretchSvg />
    <EmptyBlock heightPercent={.1}/>
    <TextBox />
    <GlitchImage 
      imagePaths={{ webp: '/media/glitchImage/rage.webp', png: '/media/glitchImage/rage.png' }} 
      altText='3d render of Rage' 
      sizePercentage={.21}
      sizePercentagePortrait={.69}
      glitchIntensity={.0169}
    />
    <Contact />
    <EmptyBlock heightPercent={.42}/>
    <ScrollStretchSvg />
    <Footer />
  </>)
};

const RageWebsite = () => {
  return (
    <>
      <DemoPage />
    </>
  );
};

export default RageWebsite;