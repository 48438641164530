import './Contact.css';

import React from 'react';

const Contact = ({
    style = {
        color: '#ffffff',
        highlightColor: '#00ff33',
    },
}) => {
  return (
    <div className='contact'>
        <h2 style = {{ color: style.color }} >Contact</h2>
        <span className='mail'><a style = {{ color: style.highlightColor }} href='mailto:rage@rage.exposed'>rage a̵̦͌t̸̨̏ rage d̶̪͘o̸͝ͅṫ̴͇ exposed </a> </span>
        <br />
        <br />
        <span className='insta'><a style = {{ color: style.highlightColor }} href='https://www.instagram.com/r.a.g.e_' target='_blank'>instagram d̶̪͘o̸͝ͅṫ̴͇ com s̶̰̃l̶͔̑a̷̜͍̓s̵̼͆͌h̶͇̓͘ rage </a> </span>
        <br />
        <br />
        <span className='name'>[ Regina Fuchs ]</span>
    </div>
  );
};

export default Contact;