import './App.css';
import RageWebsite from './components/RageWebsite/RageWebsite';
//import StroboWebsite from './components/StroboWebsite/StroboWebsite';

const App = () => {

  return (
    <div className="App">
        <RageWebsite />
    </div>
  );
};

export default App;