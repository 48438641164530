import './TextBox.css';

import React, {useState, useEffect, useRef} from 'react';
import {debounce} from 'lodash';


const randomChar = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return chars.charAt(Math.floor(Math.random() * chars.length));
}

const randomizeWord = word => word.split('').map(c => randomChar()).join('');

const randomizeText = (text, highlightKeywords) => text.split(' ')
    .map(word => highlightKeywords.includes(word) ? word : randomizeWord(word))
    .join(' ');

const placeholderText = "Rage (Germany, 1996) is a multidisciplinary artist based in Vienna , AT. Starting from her roots in painting, she uses a variety of media in the physical as well as the digital space to create works that allow a look into the depths of the psyche. In her art, she combines the uncanny with the melancholic, the deep calm with the unrest. In the course of her artistic research, she came across a unique material that she uses for sculpture and experimental painting: micro fibrillated nano-cellulose. Her work often has a deep connection to music and sound art. She is creating animated music videos, live visuals, cover artworks and fashion for bands and musicians such as Aze, Wanda or Laikka. Furthermore, she builds multi-sensory immersive experiences: her VR artwork has for example been exhibited at Parallel Vienna 2022.";

const TextBox = ({
    style = {
        color: '#ffffff',
        highlightColor: '#00ff33',
    },
    glitch = true,
    highlightKeywords = ['Rage', 'transmedia', 'animation', '3D', 'painting', 'sculpture', 'experimental', 'nano-cellulose', 'immersive', 'animated', 'sound', 'videos', 'VR', 'multidisciplinary', 'slay', 'digital'],
    text = placeholderText
}) => {
    const [currentText, setCurrentText] = useState(text);

    // glitch
    const onScrollEnd = () => debounce(() => setCurrentText(text), 210);
    const onScroll = () => {
        setCurrentText(randomizeText(text, highlightKeywords));
        onScrollEnd()();
    }
    useEffect(() => {
        if(!glitch) return;
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    



    return (
        <p 
            className={`textBox`}
            style = {{ color: style.color }}
        >
            {
                currentText.split(' ')
                    .map(word => 
                        highlightKeywords.includes(word) 
                            ? (<><span className='highlight' style={{color: style.highlightColor}}>{`${word} `}</span></>) 
                            : `${word} `
                    )
                    
            }
        </p>
    );
};

export default TextBox;